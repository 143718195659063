import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import styled from 'styled-components';
import { RootRouter } from './utils/Router';

interface AppProps {}

export const App: React.FC<AppProps> = (props) => {
	return (
		<AppBody>
			<BrowserRouter>
				<RootRouter />
			</BrowserRouter>
		</AppBody>
	);
};

const AppBody = styled.div`
	height: 100%;
`;
