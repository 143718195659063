import React from 'react';
import { HomeView } from '../screens/home/HomeView';

interface RouteConfig {
	path: string;
	comp?: React.FC;
	subRoutes?: RouteConfig[];
}

export const routes: RouteConfig[] = [
	{ path: '/', comp: HomeView }
];
