import styled from 'styled-components';
import logo from '../assets/liverewards_logo.png';
import { BoxWrapper, Fill, IconButton, Row, Text } from '../parts';

const ICON_SIZE = 18;
const ICON_COLOR = '#454444';

export const Header: React.FC = () => {
	const go = (v: string) => {
		window.location.assign(v);
	};

	return (
		<HeaderB>
			<Cont row>
				<Logo src={logo} alt='logo'
						 onClick={() => window.open('https://liverewardsgo.com', '_self')}  />
				<Fill />
				<TextBtn>Home</TextBtn>
				<TextBtn>Articles</TextBtn>
				{/*<Icons>*/}
				{/*	<Iconbtn name="instagram" size={ICON_SIZE} color={ICON_COLOR} onClick={() => go('https://www.instagram.com/liverewardstoday/')} />*/}
				{/*	<Iconbtn name="tiktok" size={ICON_SIZE} color={ICON_COLOR} onClick={() => go('https://www.tiktok.com/@liverewards?is_copy_url=1&is_from_webapp=v1')} />*/}
				{/*	<Iconbtn name="twitter" size={ICON_SIZE} color={ICON_COLOR} onClick={() => go('https://twitter.com/LiveRewardsgo')} />*/}
				{/*	<Iconbtn name="linkedin" size={ICON_SIZE} color={ICON_COLOR} onClick={() => go('https://www.linkedin.com/company/live-rewards/')} />*/}
				{/*	<Iconbtn name="facebook" size={ICON_SIZE} color={ICON_COLOR} onClick={() => go('https://www.facebook.com/Live-Rewards-107599831487842')} />*/}
				{/*</Icons>*/}
				<Icons>
					<Iconbtn name='instagram' size={ICON_SIZE} color={ICON_COLOR}
									 onClick={() => go('https://www.instagram.com/liverewardstoday/')} />
					<Iconbtn name='tiktok' size={ICON_SIZE} color={ICON_COLOR}
									 onClick={() => go('https://www.tiktok.com/@liverewards?is_copy_url=1&is_from_webapp=v1')} />
					<Iconbtn name='twitter' size={ICON_SIZE} color={ICON_COLOR}
									 onClick={() => go('https://twitter.com/LiveRewardsgo')} />
					<Iconbtn name='linkedin' size={ICON_SIZE} color={ICON_COLOR}
									 onClick={() => go('https://www.linkedin.com/company/live-rewards/')} />
					<Iconbtn name='facebook' size={ICON_SIZE} color={ICON_COLOR}
									 onClick={() => go('https://www.facebook.com/Live-Rewards-107599831487842')} />
				</Icons>
			</Cont>
		</HeaderB>
	);
};

const HeaderB = styled.div``;

const Logo = styled.img`
	height: 100px;
	width: 120px;
	object-fit: contain;
	cursor: pointer;
	transition: opacity .1s ease-in-out;
	&:hover {
		opacity: 0.8;
	}
  @media only screen and (max-width: 500px) {
    width: 80px;
    height: 70px;
  }
`;

const Cont = styled(BoxWrapper)`
  max-width: 1200px;
	
  @media only screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 600px) {
    padding-bottom: 30px;
    justify-content: end;
  }
`;

const Icons = styled(Row)`
  @media only screen and (max-width: 700px) {
    justify-content: end;
    div {
      height: 18px;
      width: 18px;
      margin: 0px 3px;
    }
  }
`;

const Iconbtn = styled(IconButton)`
  margin: 0px 5px;
  border-radius: 50px;
  background-color: #eeeeee;
  padding: 8px;

  transition: opacity 0.1s ease-in-out;

  &:hover {
    opacity: 0.3;
  }
`;

const TextBtn = styled(Text)`
  margin: 0px 20px;
  cursor: pointer;
	font-weight: 600;

  transition: opacity 0.1s ease-in-out;

  &:hover {
    opacity: 0.3;
  }
`;

const Inpt = styled.input`
  outline: none;
  border: none;
  background-color: #f1f1f1;
  padding: 14px 22px 14px 12px;
  border-radius: 10px;

  &::placeholder {
    color: #6e8098;
  }
`;
