import * as React from 'react';
import styled from 'styled-components';
import { Route, Routes } from 'react-router-dom';
import { routes } from './routes';
import { Helmet } from 'react-helmet';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';

export const RootRouter: React.FC = () => {
	return (
		<>
			<Container>
				{/* <Helmet title="Live Rewards Blog"></Helmet> */}
				<Header />
				<Routes>
					{routes.map((v, i) => {
						if (v?.subRoutes) {
							if (v.comp) return v.subRoutes.map((v2, i2) => <Route key={i * i2} path={v.path + v2.path} element={<v2.comp />} />);
						} else {
							return <Route key={i} path={v.path} element={<v.comp />} />;
						}
					})}
				</Routes>
			</Container>
			<Footer />
		</>
	);
};

const Container = styled.div`
	height: 100%;
	min-height: calc(100vh - 140px);
	background-color: ${({ theme }) => theme.background};
	color: ${({ theme }) => theme.text};
`;
