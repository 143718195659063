import * as React from 'react';
import styled from 'styled-components';
import { BoxWrapper, Row, Spacer } from '../../parts';

const blogs = [
	{
		image: 'https://blog.accessdevelopment.com/hubfs/AdobeStock_194308916-1.jpeg',
		time: 'Posted by Kendra Lusty on Nov 26, 2021 10:00:00 AM',
		title: 'Going Somewhere? How Live Rewards is Transforming Discounts for Travelers',
		desc: `Rewards are psychologically powerful tools for influencing behavior. People offer rewards to encourage strangers to help search for their lost items or pets. Grade schoolers will study extra hard on their spelling tests to earn a bonus recess. And my stubborn 8-year-old would probably still be in diapers if it`
	},
	{
		image: 'https://blog.accessdevelopment.com/hubfs/National%20consumer%20study%20rebrand.png',
		time: 'Posted by Gary Toyn on Aug 17, 2021 9:27:00 AM',
		title: 'Research: How Far Will Consumers Travel to Make Routine Purchases?',
		desc: 'For decades, the conventional wisdom has touted this stat: 80% of consumer spending occurs within 20 miles of home. We\'ve cited that stat before, as have countless other respected sources like Forbes, Ebay, and MediaPost. All of which have quoted this statistic without attribution. The problem is, the stat is a myth.'
	},
	{
		image: 'https://blog.accessdevelopment.com/hubfs/AdobeStock_194308916-1.jpeg',
		time: 'Posted by Fay Josef on Jul 19, 2021 1:15:27 PM',
		title: '2021 Customer Engagement & Loyalty Statistics',
		desc: `It's 2021 and if you're anything like us, you probably feel like you're having to learn how to engage members all over again. The past year may have thrown the industry for a collective loop, but statistics are showing a promising future for businesses willing to learn what their customers and members want right now....`
	},
	{
		image: 'https://blog.accessdevelopment.com/hubfs/5-questions-a-1024x576.png',
		time: 'Posted by Doris Laurynas on Jul 14, 2021 6:51:07 PM',
		title: '2021 Travel and Tourism Statistics',
		desc: `It's 2021 and statistics are showing that people are travelling once again. After a solid year of staying home, masking up and keeping our collective distance, people are hungry to explore the world. Is it any wonder that when it comes to the best corporate discount programs, association member benefits, white-label r...`
	},
	{
		image: 'https://blog.accessdevelopment.com/hubfs/Open%20Enrollment%20Fall%20Blog%20Post.jpg',
		time: 'Posted by Kendra Lusty on Jun 25, 2021 9:00:00 AM',
		title: 'From Bare Minimum to Concierge-Level Customer Service: How to Build Your Arsenal',
		desc: 'Last week, we released the article Superior Customer Service: Your Loyalty Program’s Secret Weapon. In it, we discussed the importance of customer service to any business, but especially to those with loyalty programs, which rely on a positive customer experience to succeed. In part 2 of this series below, we give some...'
	},
	{
		image: 'https://blog.accessdevelopment.com/hubfs/travel%20agent.jpeg',
		time: 'Posted by Gary Toyn on Jun 16, 2021 12:01:00 PM',
		title: 'White Label Travel Platforms: The Ultimate Buyers Guide',
		desc: 'How to Vet and Choose a White Label Travel Portal / Private Label Travel Booking Engine  Want to know how to identify a white label travel solution for your organization? This comprehensive buyers guide gives you the tools you need to identify and assess the best platform for your organization or company. About 19 min.'
	},
	{
		image: 'https://blog.accessdevelopment.com/hubfs/businessman%20demonstrating%20membership%20perks-1.jpeg',
		time: 'Posted by Sameer Dragoslav on May 25, 2021 10:00:00 AM',
		title: `Your Loyalty Program's Secret Weapon: Superior Customer Service`,
		desc: `Confession time. Whenever I have to call customer service, I always cringe, waiting for that dreaded phone tree before I ever get to talk to a person. I think my record is 18 selections before I got frustrated and pounded 0, which wasn’t listed but thankfully sent me to the operator anyway. I prefer to use a company’s ...`
	},
	{
		image: 'https://blog.accessdevelopment.com/hubfs/Perks-Article-web-1.jpg',
		time: 'Posted by Diklah Ansley on May 4, 2021 10:00:00 AM',
		title: 'The Top Ten Benefits of Customer Retention',
		desc: 'It’s hard to believe that so many companies still devote most of their resources to new business. Don’t get us wrong, a new customer is always a worthy pursuit. But this laser focus on the present often comes at the expense of the future. US companies spend $50 billion yearly on loyalty programs.  Meanwhile, businesses...'
	},
	{
		image: 'https://fs.hubspotusercontent00.net/hub/263750/file-1148231718.jpg',
		time: 'Posted by Sophia Iiris on Dec 15, 2020 9:54:33 AM',
		title: 'The 2020 LiveRewards Development Year in Review',
		desc: 'As 2020 draws to a close, the air is filled with comments like: “There’s no place like home for the holidays… too bad we can’t go.” and “Happy New Year… we hope.” and of course, “Is this dumpster fire of a year over yet?” Indeed it has been a difficult year for many. Some said goodbye to loved ones too early. Some stru...'
	}
];

export const HomeView: React.FC = ({}) => {

	return (
		<HomeViewBody>
			<PageCont row>

				<Spacer size={20} />
				<SubscribeBox>
					<SubTopBox>
						<p>Engagement & Loyalty Tips Delivered to Your Inbox</p>
					</SubTopBox>
					<SubContentPart>
						<p>Email*</p>
						<Input placeholder='Email' type='email' />
						<SubmutBtn>Subscribe</SubmutBtn>
					</SubContentPart>
				</SubscribeBox>
				<Spacer size={20} />
				<div>
					{blogs?.map((item, index) =>
						<BlogPost key={index}>
							<PostHeading>
								<p>{item.title}</p>
							</PostHeading>
							<ContentPart>
								<Img src={item.image} />
								<div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
									<p style={{ fontStyle: 'italic', color: 'gray' }}>{item.time}</p>
									<Spacer size={20} />
									<p style={{ letterSpacing: 0.3 }}>{item.desc}</p>
								</div>
							</ContentPart>
						</BlogPost>
					)}
				</div>
				<Spacer size={20} />
			</PageCont>

		</HomeViewBody>
	);
};

const HomeViewBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

const PageCont = styled(BoxWrapper)`
  align-items: flex-start;
  @media only screen and (max-width: 750px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Input = styled.input`
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #d3d3d3;
  margin-bottom: 15px;
`;

const SubscribeBox = styled.div`
  max-width: 240px;

  p {
    &:nth-child(1) {
      font-weight: 900;
      font-size: 22px;
      text-align: center;
    }
  }
`;

const SubContentPart = styled.div`
  border: 1px solid #d3d3d3;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    &:nth-child(1) {
      color: gray;
      padding-bottom: 5px;
      font-weight: lighter;
      font-size: 12px;
      text-align: left;
    }
  }
`;

const SubTopBox = styled.div`
  background-color: #2E74E6;

  p {
    color: white;
    padding: 20px 0px;
  }
`;

const BlogPost = styled.div`
  max-width: 900px;
  margin-bottom: 40px;
`;

const Img = styled.img`
  height: 150px;
  width: 250px;
  object-fit: contain;
  border-radius: 4px;
`;

const PostHeading = styled.div`
  background-color: #4052C7;
  background: linear-gradient(90deg, rgba(46, 118, 232, 1) 0%, rgba(73, 46, 169, 1) 100%);
  padding: 25px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  p {
    color: white;
    font-weight: 800;
    font-size: 34px;
    text-align: center;
  }
`;

const ContentPart = styled(Row)`
  padding: 25px 20px;
  align-items: normal;
  border: 1px solid #d3d3d3;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  img {
    margin-right: 25px;
  }

  @media only screen and (max-width: 520px) {
    flex-direction: column;
    align-items: center;
    img {
      margin-bottom: 10px;
    }
  }
`;

const SubmutBtn = styled.button`
  border-radius: 4px;
  background-color: #41A7FA;
  outline: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 10px;
  font-weight: 900;
  font-family: bozon;
`;
