import { CSSProperties } from 'react';
import styled from 'styled-components';
import { Icon } from './';

interface BoxWrapperConf {
	row?: boolean;
	style?: CSSProperties;
	smaller?: boolean;
	children?: React.ReactNode;
}

export const BoxWrapper: React.FC<BoxWrapperConf> = (p) => {
	return <BoxWrapperB {...p}>{p.children}</BoxWrapperB>;
};

const BoxWrapperB = styled.div<BoxWrapperConf>`
	display: flex;
	flex-direction: ${({ row }) => (row ? 'row' : 'column')};
	align-items: center;
	padding: 5px 20px;

	${({ smaller }) => `
	@media only screen and (min-width: ${smaller ? 800 : 1100}px) {
		justify-content: center;
		max-width: ${smaller ? 800 : 1100}px;
		align-items: center;
		margin: 0 auto;
	}
	`}
`;

export const Fill: React.FC = () => {
	return <FillB />;
};

const FillB = styled.div`
	display: flex;
	flex: 1;
	flex-grow: 1;
`;

interface IconButtonProps extends CSSProperties {
	name: string;
	color: string;
	size: number;
	onClick?: () => void;
}

export const IconButton: React.FC<IconButtonProps> = (p) => {
	return <IconButtonB {...p} name={p.name} color={p.color} size={p.size} />;
};

const IconButtonB = styled(Icon)`
	cursor: pointer;
	&:hover {
		fill: red;
	}
`;

export const Spacer: React.FC<{ size: number }> = (p) => {
	return <SpacerBody {...p} />;
};

const SpacerBody = styled.div<{ size: number }>`
	height: ${({ size }) => size}px;
	width: ${({ size }) => size}px;
`;

interface RowConfig {
	style?: CSSProperties;
	center?: boolean;
	children?: React.ReactNode;
}

export const Row: React.FC<RowConfig> = (p) => {
	const { children, style } = p;
	return (
		<RowBody {...p} style={style}>
			{children}
		</RowBody>
	);
};

const RowBody = styled.div<RowConfig>`
	display: flex;
	align-items: center;
	${({ center }) => center && 'justify-content: center;'}
`;
