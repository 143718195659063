import * as React from 'react';
import styled from 'styled-components';
import logo from '../assets/liverewards_logo.svg';
import { BoxWrapper, Fill, IconButton, Row, Spacer, Text } from '../parts';

interface FooterProps {}
const ICON_SIZE = 23;
const ICON_COLOR = '#454444';

export const Footer: React.FC<FooterProps> = (props) => {
	const go = (v: string) => {
		window.location.assign(v);
	};

	return (
		<FooterBody>
			<Cont row>
				<Row>
					<LogoIMG src={logo} alt="logo" />
					<Spacer size={20} />
					<Text> 2021 copyright all rights reserved</Text>
				</Row>
				<Fill />
				<Icons>
					<Iconbtn name="instagram" size={ICON_SIZE} color={ICON_COLOR} onClick={() => go('https://www.instagram.com/liverewardstoday/')} />
					<Iconbtn name="twitter" size={ICON_SIZE} color={ICON_COLOR} onClick={() => go('https://www.tiktok.com/@liverewards?is_copy_url=1&is_from_webapp=v1')} />
					<Iconbtn name="linkedin" size={ICON_SIZE} color={ICON_COLOR} onClick={() => go('https://www.linkedin.com/company/live-rewards/')} />
					<Iconbtn name="facebook" size={ICON_SIZE} color={ICON_COLOR} onClick={() => go('https://www.facebook.com/Live-Rewards-107599831487842')} />
				</Icons>
			</Cont>
		</FooterBody>
	);
};

const FooterBody = styled.div`
	height: 100px;
	border-top: solid 1px #e8e8e8;
	width: 100%;
`;

const LogoIMG = styled.img`
	height: 100px;
`;

const Icons = styled(Row)`
	@media only screen and (max-width: 700px) {
		div {
			height: 18px;
			width: 18px;
			margin: 0px 3px;
		}
	}
	@media only screen and (max-width: 500px) {
		margin-bottom: 20px;
	}
`;
const Iconbtn = styled(IconButton)`
	margin: 0px 10px;

	transition: opacity 0.1s ease-in-out;
	&:hover {
		opacity: 0.3;
	}
`;

const Cont = styled(BoxWrapper)`
	flex-wrap: wrap;
`;
